import React, { useEffect, useState } from "react";
import { items } from "./items";
import './MultiFilters.scss';


export default function MultiFilters() {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredItems, setFilteredItems] = useState(items);

  let filters = [
    "GENERATIVE AI",
    "CHAT",
    "PRODUCTIVITY","SELF IMPROVEMENT",
    "RESEARCH",
    "SOCIAL MEDIA","IMAGE GENERATION",
    "COPYWRITING",
    "GENERATIVE CODE","GENERATIVE VIDEO","IMAGE IMPROVEMENT",
    "GAMING","PROMPT GUIDES","MUSIC","SPEECH-TO-TEXT",
    "GENERATIVE ART","FINANCE","PODCASTING","MARKETING",
    "TEXT-TO-VIDEO","TEXT-TO-SPEECH",
    "OTHER",
  ];

  const handleFilterButtonClick = (selectedCategory) => {
    if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory);
      setSelectedFilters(filters);
    } else {
      setSelectedFilters([...selectedFilters, selectedCategory]);
    }
  };

  useEffect(() => {
    filterItems();
  }, [selectedFilters]);

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = items.filter((item) => item.category === selectedCategory);
        return temp;
      });
      setFilteredItems(tempItems.flat());
    } else {
      setFilteredItems([...items]);
    }
  };

  return (
    <div>
      <div className="buttons-container">
        {filters.map((category, idx) => (
          <button
            onClick={() => handleFilterButtonClick(category)}
            className={`button ${
              selectedFilters?.includes(category) ? "active" : ""
            }`}
            key={`filters-${idx}`}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="items-container">
        {filteredItems.map((item, idx) => (
          <div key={`items-${idx}`} className="item">
            <a href={item.link} title="Click here to read the full article on dinosaurs" target="_blank" ><h1><p>{item.title}</p></h1></a>
            <h2><p className="category">{item.labels}</p></h2>
            <p>{item.description}</p>
           <a href={item.link} className="know-more" target="_blank" title="Click here to read the full article on dinosaurs"><b>Know More . .</b></a>
          </div>
        ))}
      </div>
    </div>
  );
}
