import React from "react";
import './App.scss';
import Footer from './footer.jsx'
import Head from './header.jsx';
import clip from './video.mp4'; 
import Multi from './MultiFilters.js'



function App() {
  return (
    <div className="app">  
 <video id="background-video" autoPlay loop muted playsInline>
            <source src={clip} type='video/mp4' />          
  </video> 
       <Head/>   
      <Multi/>         
      <Footer/>  
    </div>
);
  
}

export default App;
