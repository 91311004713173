import './footer.scss';
import {FaHeart, FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube } from 'react-icons/fa';
const Footer = () => {
    return (

                <div className='footer'>
                        <div className='icon'>
                        <FaFacebook size={38} color="#3b5998"/>
                        <FaInstagram size={38} color="#3b5998" />
                        <FaTwitter size={38} color="#3b5998"/>
                        <FaLinkedinIn size={38} color="#3b5998"/>
                        <FaYoutube size={38} color="red"/>
                        </div>

                    <ul>
                        <li>About Us</li>
                        <li>Contact Us</li>
                        <li>Teach with Us</li>
                        <li>Cancellation/Refund Policy</li>
                        <li>Privacy Policy</li>
                        <li> Terms of use</li>
                        <li>Guest Posting</li>
                    </ul>
                    <ul>Copyright 2024 <a href='https://www.devopsfarm.in/'><b>DEVOPS FARM</b></a></ul>
                    <ul><b>Made With <FaHeart color='red' /> In India</b></ul>   
                    </div>
    
    )}


    export default Footer;


