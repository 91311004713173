import './header.scss';
import logo from './devopsfarm-hires-logo.png'

const Head = () => {
    return (
        <div>
        <div className = 'head'>
                <img className="rotating-logo" src={logo}  />
             
            </div>

            

                </div>
    )
}

export default Head;